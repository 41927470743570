<template>
  <collapse-card :model-value="false">
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Character Details
      </span>
    </template>
    <template #cardContent>
      <div
        v-if="charactersDetailsLoading"
        class="flex justify-center p-40"
      >
        <base-svg
          class="h-4 w-4 mr-1 text-primary-red inline-block"
          src="icons/circleSpinner.svg"
          tag="span"
        />
        Loading ...
      </div>
      <div
        v-else
        class="py-2 px-4"
      >
        <div>
          <div class="grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
            <base-input
              v-model="dealSummaryData.characterDetails.searchKeyword"
              type="text"
              container-class="flex items-center"
              label="Search"
              label-class="label-w-46"
            />
            <div class="flex">
              <base-button
                variant="btn-primary"
                :disabled="!dealSummaryData.characterDetails.searchKeyword.length"
                class="mr-3"
                text="Search"
                @click.stop.prevent="handlePageUpdate(1)"
              />
              <base-button
                variant="btn-link"
                text="Cancel"
                @click="resetSearch()"
              />
            </div>
          </div>
        </div>
        <base-input
          v-model="dealSummaryData.characterDetails.selectedStyleGuide"
          type="text"
          container-class="flex items-center mb-4"
          disabled
          label="Style Guide"
          label-class="label-w-46"
        />
        <div class="flex justify-end mb-4">
          <base-pagination
            :model-value="dealSummaryData.characterDetails.tableData.page"
            :total-pages="dealSummaryData.characterDetails.tableData.pageCount"
            @update:modelValue="handlePageUpdate($event)"
          />
        </div>
        <brand-assurance-table
          v-model="dealSummaryData.characterDetails.tableData"
          root-element-class="mb-10"
          :show-check-box-column="false"
        />
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { defineAsyncComponent, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import useDealSummary from './dealSummary.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { formatDate } from '@/helpers/util';
import { NO_CHARACTERS_FOUND, WARNING } from '@/constants/alerts.js';
export default {
    name: 'CharacterDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue'))
    },

    setup () {
        const store = useStore();
        const { dealSummaryData } = useDealSummary();
        const { notificationsStack } = useToastNotifications();

        const resetSearch = () => {
            dealSummaryData.characterDetails.searchKeyword = '';
            fetchCharactersDetails();
        };
        onMounted(() => {
            fetchCharactersDetails();
        });
        const fetchedCharactersDetails = ref([]);
        const charactersDetailsLoading = ref(false);
        const fetchCharactersDetails = async () => {
            try {
                charactersDetailsLoading.value = true;
                store.getters['baStyleGuides/getDrmStyleGuidesList'];

                let styleGuideId = '';
                if (dealSummaryData.characterDetails.selectedStyleGuide) {
                    const __styleGuides = await store.dispatch('baStyleGuides/fetchDrmStyleGuideContractList', {
                        params: {
                            searchKeyword: dealSummaryData.styleGuideDetails.searchKeyword,
                            page: dealSummaryData.styleGuideDetails.tableData.page,
                            limit: dealSummaryData.styleGuideDetails.tableData.limit,
                            contractNumber: dealSummaryData.contractNumber
                        },
                        onlyReturnValues: true
                    });

                    const tmpSg = __styleGuides.data.find(sg => dealSummaryData.characterDetails.selectedStyleGuide === sg.styleGuide);
                    if (tmpSg && tmpSg.styleGuideId) styleGuideId = tmpSg.styleGuideId;
                }

                fetchedCharactersDetails.value = await store.dispatch('baStyleGuides/fetchDrmStyleGuideCharactersList', {
                    params: {
                        styleGuideId,
                        searchKeyword: dealSummaryData.characterDetails.searchKeyword,
                        page: dealSummaryData.characterDetails.tableData.page,
                        limit: dealSummaryData.characterDetails.tableData.limit
                    },
                    id: dealSummaryData.contractNumber,
                    onlyReturnValues: true
                });
                dealSummaryData.characterDetails.tableData.data = fetchedCharactersDetails.value.data.map(obj => {
                    obj.startDate = formatDate(obj.startDate);
                    obj.endDate = formatDate(obj.endDate);
                    return obj;
                });
                dealSummaryData.characterDetails.tableData.pageCount = fetchedCharactersDetails.value.pageCount;
                dealSummaryData.characterDetails.tableData.totalCount = fetchedCharactersDetails.value.totalCount;
                if (dealSummaryData.characterDetails.tableData.data.length === 0) {
                    notificationsStack.value.push({
                        type: WARNING,
                        message: NO_CHARACTERS_FOUND
                    });
                }
            } catch (err) {
                console.error(err);
            } finally {
                charactersDetailsLoading.value = false;
            }
        };
        const handlePageUpdate = (newPage) => {
            dealSummaryData.characterDetails.tableData.page = newPage;
            fetchCharactersDetails();
        };
        watch(
            () => dealSummaryData.characterDetails.selectedStyleGuide,
            () => {
                handlePageUpdate(1);
                resetSearch();
            }
        );

        return {
            dealSummaryData,
            resetSearch,
            fetchCharactersDetails,
            handlePageUpdate,
            charactersDetailsLoading
        };
    }
};
</script>
